import {
    PublicContentVaultLink,
    PublicContentK33,
    PublicContentCrossx,
    PublicContentCognitoDev,
    PublicContentLMOTCDemo,
    PublicContentLaserDigital,
    PublicContentPro,
    PublicContentCoinhub,
} from './Firms'

const PublicContentFirmMapper: Record<string, () => JSX.Element> = {
    VaultLink: PublicContentVaultLink,
    K33: PublicContentK33,
    Crossx: PublicContentCrossx,
    CognitoDev: PublicContentCognitoDev,
    LMOTCDemo: PublicContentLMOTCDemo,
    LaserDigital: PublicContentLaserDigital,
    Pro: PublicContentPro,
    Coinhub: PublicContentCoinhub,
}

export default PublicContentFirmMapper
