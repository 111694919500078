/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useRef, useCallback, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Form, { Item, Label, ButtonItem, ButtonOptions, RequiredRule, PatternRule } from 'devextreme-react/form'
import { useAuth, useConfig } from '../../../contexts'
import { LoginFormProps, createAnAccountEnumType } from './Model'
import { ErrorMessage, Loading, SingleCard } from '../../common'
import { GoToPage } from '../../../utils/goToPage'
import '../form.scss'
import './Styles.scss'
import { AuthResponse } from '../../../clients/api/auth'
import { ChallengeEnum, HttpErrorCodes } from '../../../clients/api/auth/Model'
import { Const } from '../../../const'
import { useCookies } from 'react-cookie'
import { ConfigStateEnum } from '../../../contexts/config/Model'
import { ErrorMessageProps } from '../../common/errorMessage/Model'
import { AppConfigModel } from 'clients/api/config'

const errorMessageClear = new ErrorMessageProps()

const LoginForm = (): JSX.Element => {
    const [cookies] = useCookies(['token'])
    const { commonConfig, loadingCommonConfig } = useConfig()
    const navigate = useNavigate()
    const { signIn, storeRememberMe, rememberMe, storeUsername, username, loading, initializeRedirectToAppCount, incrementRedirectToAppCount, redirectToAppCount } = useAuth()
    const [errorMessage, setErrorMessage] = useState(errorMessageClear)

    const loginFormData = new LoginFormProps(username)

    const formData = useRef({ username: loginFormData.username, password: '', rememberMe })
    const customContent = commonConfig.Content.SignIn
    const useEmail = customContent.useEmailAsUsername
    const hasForgotPassword = customContent.forgotPasswordLink
    const hasChangePassword = customContent.changePasswordLink
    const userLabel = customContent.userInputText
    const hasRememberMe = customContent.hasRememberMe
    const inputMode = commonConfig.Content.Common.inputType
    const signInMessage = customContent.signInMessage
    const signInMessageLink = customContent.signInMessageLink

    const appConfig = commonConfig.AppConfig
    const showCreateAccountLinkMobile = customContent.showCreateAccountLinkMobile ? 'login-link-show' : 'login-link-hide'

    let redirectToAppTime = appConfig.redirectToAppTime
    if (redirectToAppTime <= 0) redirectToAppTime = AppConfigModel.DefaultRedirectToAppTime

    let maxRedirectsToApp = appConfig.maxRedirectsToApp
    if (maxRedirectsToApp <= 0) maxRedirectsToApp = AppConfigModel.DefaultMaxRedirectsToApp

    useEffect(() => {
        if (loadingCommonConfig === ConfigStateEnum.Loaded && cookies.token && redirectToAppCount < maxRedirectsToApp) {
            // Redirect to the app: extend the timer on each redirect attempt
            setTimeout(() => {
                GoToPage.App()
                incrementRedirectToAppCount()
            }, 100 + redirectToAppTime * redirectToAppCount)
        }
    }, [cookies.token, incrementRedirectToAppCount, loadingCommonConfig, maxRedirectsToApp, redirectToAppCount, redirectToAppTime])

    const onSubmit = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async (e: any): Promise<void> => {
            e.preventDefault()
            let { username, password } = formData.current
            username = username.trim()
            try {
                setErrorMessage(errorMessageClear)
                const authResponse: AuthResponse = await signIn(username, password)
                if (authResponse.code === HttpErrorCodes.TemporaryPasswordExpired) {
                    setErrorMessage(new ErrorMessageProps(commonConfig.Content.SignIn.temporaryPasswordExpiredMessage))
                    return
                }
                if (authResponse.code !== HttpErrorCodes.OK) {
                    setErrorMessage(new ErrorMessageProps('Sign In Error: ' + (authResponse.message ?? 'no error message received')))
                    return
                }

                if (authResponse.challenge == null) {
                    GoToPage.App()
                    initializeRedirectToAppCount()
                }

                switch (ChallengeEnum[authResponse.challenge as keyof typeof ChallengeEnum]) {
                    case ChallengeEnum.SOFTWARE_TOKEN_MFA:
                        navigate('/two-factor-auth-verify', { state: { isRegistered: ChallengeEnum.SOFTWARE_TOKEN_MFA } })
                        break
                    case ChallengeEnum.MFA_SETUP_LM:
                        navigate('/two-factor-auth-register', { state: { userName: formData.current.username } })
                        break
                    case ChallengeEnum.NEW_PASSWORD_REQUIRED:
                        navigate('/new-password', { state: { userName: formData.current.username } })
                        break
                    default:
                        throw new Error('Sign In Error: Unknown challenge')
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (error: any) {
                const msg = error.response?.data.error == null ? 'Sign In Error' : error.response.data.error.message
                setErrorMessage(msg)
            }
        },
        [commonConfig.Content.SignIn.temporaryPasswordExpiredMessage, initializeRedirectToAppCount, navigate, signIn]
    )

    const onCreateAccountClick = useCallback(() => {
        navigate('/create-account')
    }, [navigate])

    const onUsernameChanged = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            const { username } = formData.current
            storeUsername(username)
        },
        [storeUsername]
    )

    const usernameEditorOptions = {
        stylingMode: 'filled',
        label: userLabel,
        labelMode: inputMode,
        mode: 'text',
        onValueChanged: onUsernameChanged,
        elementAttr: {
            'field-id': 'username-code-id',
        },
    }

    const emailEditorOptions = {
        stylingMode: 'filled',
        label: userLabel,
        labelMode: inputMode,
        mode: 'email',
        onValueChanged: onUsernameChanged,
        elementAttr: {
            'field-id': 'email-code-id',
        },
    }

    const passwordEditorOptions = {
        stylingMode: 'filled',
        label: 'Password',
        labelMode: inputMode,
        mode: 'password',
        elementAttr: {
            'field-id': 'password-code-id',
        },
    }

    const onRememberMeChanged = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (e: any): void => {
            const rememberMeValue = e.value
            storeRememberMe(rememberMeValue)
        },
        [storeRememberMe]
    )

    const rememberMeEditorOptions = {
        text: 'Remember my username',
        elementAttr: {
            class: 'form-text',
            'field-id': 'remember-me-code-id',
        },
        onValueChanged: onRememberMeChanged,
    }

    const createAnAccountType: createAnAccountEnumType = customContent.createAnAccountType

    return (
        <SingleCard {...customContent}>
            <form className={'login-form'} onSubmit={onSubmit}>
                <Form formData={formData.current} disabled={loading}>
                    {!useEmail ? (
                        <Item dataField={'username'} editorType={'dxTextBox'} editorOptions={usernameEditorOptions}>
                            <RequiredRule message="Username is required" />
                            <Label visible={false} text={userLabel} />
                        </Item>
                    ) : (
                        <Item dataField={'username'} editorType={'dxTextBox'} editorOptions={emailEditorOptions}>
                            <RequiredRule message="Email is required" />
                            <PatternRule message="Email is not valid" pattern={Const.EmailValidatorPattern} />
                            <Label visible={false} text={userLabel} />
                        </Item>
                    )}
                    <Item dataField={'password'} editorType={'dxTextBox'} editorOptions={passwordEditorOptions}>
                        <RequiredRule message="Password is required" />
                        <Label visible={false} />
                    </Item>
                    {hasRememberMe && (
                        <Item dataField={'rememberMe'} editorType={'dxCheckBox'} editorOptions={rememberMeEditorOptions}>
                            <Label visible={false} />
                        </Item>
                    )}
                    {hasChangePassword && (
                        <Item>
                            <div className={'link'}>
                                <Link to={'/change-password'}>{customContent.changePasswordText}</Link>
                            </div>
                        </Item>
                    )}
                    <ButtonItem>
                        <ButtonOptions width={'100%'} type={'default'} useSubmitBehavior={true}>
                            <span className="dx-button-text">{loading ? <Loading /> : customContent.submitButtonText}</span>
                        </ButtonOptions>
                    </ButtonItem>
                    {signInMessage !== '' && (
                        <Item>
                            <div className={'cardFooterMessageContainer'}>
                                <a href={signInMessageLink} className={'cardFooterMessage'}>
                                    {signInMessage}
                                </a>
                            </div>
                        </Item>
                    )}
                    {errorMessage.message !== '' && (
                        <Item>
                            <ErrorMessage {...errorMessage} />
                        </Item>
                    )}
                    {hasForgotPassword && (
                        <Item>
                            <div className={'link'}>
                                <Link to={'/forgot-password'}>{customContent.forgotPasswordText}</Link>
                            </div>
                        </Item>
                    )}
                    {createAnAccountType === createAnAccountEnumType.link && (
                        <Item>
                            <div className={`link ${showCreateAccountLinkMobile}`}>
                                <Link to={'/create-account'}>{customContent.createAnAccountText}</Link>
                            </div>
                        </Item>
                    )}
                    {createAnAccountType === createAnAccountEnumType.textAndLink && (
                        <Item>
                            <div className={`link ${showCreateAccountLinkMobile}`}>
                                <span className="text">{customContent.createAnAccountText}</span>
                                <Link to={'/create-account'}>{customContent.createAnAccountTextLink}</Link>
                            </div>
                        </Item>
                    )}
                    {createAnAccountType === createAnAccountEnumType.button && (
                        <ButtonItem>
                            <ButtonOptions text={customContent.createAnAccountText} width={'100%'} onClick={onCreateAccountClick} elementAttr={createAccountButtonAttributes} />
                        </ButtonItem>
                    )}
                </Form>
            </form>
        </SingleCard>
    )
}

const createAccountButtonAttributes = { 'field-id': 'create-account-code-id' }

export default LoginForm
