import { ConfigStateEnum } from 'contexts/config/Model'
import { useConfig } from '../contexts'
import PublicContentFirmMapper from './FirmMapper'
import './Styles.scss'

const PublicContent = (): JSX.Element => {
    const { firm, loadingCommonConfig } = useConfig()

    const publicContent = 'PublicContent'
    const firmClassName = `${publicContent}${firm}`
    const IsFirmPublicContentMapped = firm !== 'undefined' && firm in PublicContentFirmMapper
    const PublicContentFirmToLoad = IsFirmPublicContentMapped ? PublicContentFirmMapper[firm]() : <div id="no_firm_mapped" />
    const PublicContentFirm = loadingCommonConfig !== ConfigStateEnum.Loaded ? <div> Loading... </div> : PublicContentFirmToLoad

    return (
        <div className={firmClassName} id={publicContent}>
            {PublicContentFirm}
        </div>
    )
}

export default PublicContent
