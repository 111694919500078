import { v4 as uuidv4 } from 'uuid'
import axios, { AxiosInstance } from 'axios'
import {
    AuthResponse,
    ChangePasswordRequest,
    EmptyResponse,
    LoginRequest,
    NewPasswordRequest,
    ResetPasswordRequest,
    Setup2FAResponse,
    StartTokenRequest,
    UserResponse,
    UsernameAuthBaseRequest,
    Verify2FARequest,
} from './Model'
import { ICreateAccountPropsBase } from '../../../components/forms/createAccountForm/Model'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class Auth {
    private readonly axiosInstance: AxiosInstance
    private apiSequence: string
    private sessionHeader: string
    private readonly username2Lowercase: boolean

    constructor(baseUrl: string, credentials: string, requestTimeout: number, username2Lowercase: boolean) {
        this.axiosInstance = axios.create({ baseURL: baseUrl, timeout: requestTimeout })
        this.apiSequence = ''
        this.sessionHeader = ''
        this.username2Lowercase = username2Lowercase
        this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${credentials}`
        this.axiosInstance.interceptors.request.use(this.requestInterceptor.bind(this))
        this.axiosInstance.interceptors.response.use(this.responseInterceptor.bind(this))
    }

    public get IsReady(): boolean {
        return this.apiSequence !== '' && this.sessionHeader !== ''
    }

    public get IsNotReady(): boolean {
        return !this.IsReady
    }

    public async Start(): Promise<EmptyResponse> {
        return await this.startToken()
    }

    public async GetUser(queryStringReq: UsernameAuthBaseRequest): Promise<UserResponse> {
        return await this.getAsync<UsernameAuthBaseRequest, UserResponse>('user', queryStringReq)
    }

    public async Register(req: ICreateAccountPropsBase): Promise<EmptyResponse> {
        return await this.postAsync<ICreateAccountPropsBase, EmptyResponse>('register', req)
    }

    public async Login(req: LoginRequest): Promise<AuthResponse> {
        return await this.postAsync<LoginRequest, AuthResponse>('login', req, { withCredentials: true })
    }

    public async NewPassword(req: NewPasswordRequest): Promise<EmptyResponse> {
        return await this.postAsync<NewPasswordRequest, EmptyResponse>('newPassword', req)
    }

    public async Setup2FA(req: UsernameAuthBaseRequest): Promise<Setup2FAResponse> {
        return await this.postAsync<UsernameAuthBaseRequest, Setup2FAResponse>('mfa/setup', req)
    }

    public async Verify2FA(req: Verify2FARequest): Promise<EmptyResponse> {
        return await this.postAsync<Verify2FARequest, EmptyResponse>('mfa/verify', req, {
            withCredentials: true,
        })
    }

    public async VerifyAfterLogin2FA(req: Verify2FARequest): Promise<EmptyResponse> {
        return await this.postAsync<Verify2FARequest, EmptyResponse>('login/mfa', req, {
            withCredentials: true,
        })
    }

    public async ForgotPassword(req: UsernameAuthBaseRequest): Promise<EmptyResponse> {
        return await this.postAsync<UsernameAuthBaseRequest, EmptyResponse>('forgotPassword', req)
    }

    public async ChangePassword(req: ChangePasswordRequest): Promise<EmptyResponse> {
        return await this.postAsync<UsernameAuthBaseRequest, EmptyResponse>('changePassword', req)
    }

    public async ResetPassword(req: ResetPasswordRequest): Promise<EmptyResponse> {
        return await this.postAsync<UsernameAuthBaseRequest, EmptyResponse>('forgotPassword/submit', req)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private requestInterceptor(request: any): any {
        request.headers.session = this.sessionHeader
        request.headers.apisequence = this.apiSequence
        // the username must be lowercase to be sent to the server
        // TODO remove that when the cognito server is fixed to process the username as case insensitive
        if (this.username2Lowercase && request.data?.username != null) request.data.username = request.data.username.toLowerCase()
        return request
    }

    private async startToken(): Promise<EmptyResponse> {
        const req = new StartTokenRequest(uuidv4())
        return await this.postAsync<StartTokenRequest, EmptyResponse>('startToken', req)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private responseInterceptor(response: any): any {
        // if the apisequence do not found use the previous one
        if (response.headers.apisequence != null) this.apiSequence = response.headers.apisequence
        // if the session do not found use the previous one
        if (response.headers.session != null) this.sessionHeader = response.headers.session
        return response
    }

    private async checkHeaders(): Promise<void> {
        if (this.IsNotReady) {
            await this.startToken()
            throw new Error('The api is not ready!. Session or ApiSequence header values not found')
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private async postAsync<TReq, TResp>(endpoint: string, req: TReq, headers?: any): Promise<TResp> {
        await this.checkIfIsStarted(endpoint)
        const response = await this.axiosInstance.post(endpoint, { ...req }, headers).catch((err) => {
            console.error(`postAsync error: '${endpoint}' => ${JSON.stringify(err)} `)
            err.response.data.code = err.response.data.code ?? err.response.status
            return err.response
        })
        return this.processApiResponse(response)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private async getAsync<TQueryString, TResp>(endpoint: string, queryStringObj: TQueryString): Promise<TResp> {
        await this.checkIfIsStarted(endpoint)
        const queryStringRecord = queryStringObj as Record<string, string>
        const queryString = new URLSearchParams(queryStringRecord).toString()
        const url = `${endpoint}?${queryString}`
        const response = await this.axiosInstance.get(url).catch((err) => {
            console.error(`getAsync error: '${url}' => ${JSON.stringify(err)} `)
            err.response.data.code = err.response.status
            return err.response
        })
        return this.processApiResponse(response)
    }

    private async checkIfIsStarted(endpoint: string): Promise<void> {
        if (endpoint !== 'startToken') await this.checkHeaders()
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private processApiResponse(response: any): any {
        if (response.data.code === undefined) response.data.code = response.status
        return response.data
    }
}
