import { useCallback, useRef, useState } from 'react'
import CreateAccountFormIndividualStandardCoinhub from './Forms/IndividualStandard'
import { ProductTypeEnum, ProfileTypeEnum } from '../Model'
import { CreateAccountIndividualStandardCoinhubProps } from './Model'
import { SingleCard } from '../../../common'
import { useConfig } from '../../../../contexts'

const CreateAccountFormCoinhub = (): JSX.Element => {
    const { commonConfig } = useConfig()

    const [profileType, setProfileType] = useState(ProfileTypeEnum.Individual)
    const [productType, setProductType] = useState(ProductTypeEnum.Standard)
    const data = new CreateAccountIndividualStandardCoinhubProps()
    data.profile.productType = productType
    data.profile.profileType = profileType
    const formData = useRef({ ...data })

    const onProfileTypeValueChanged = useCallback(
        (newProfileType: ProfileTypeEnum): void => {
            if (profileType !== newProfileType) {
                setProfileType(newProfileType)
            }
        },
        [profileType]
    )

    const onProductTypeValueChanged = useCallback(
        (newProductType: ProductTypeEnum): void => {
            if (productType !== newProductType) {
                setProductType(newProductType)
            }
        },
        [productType]
    )

    const customContent = commonConfig.Content.SignUp

    return (
        <SingleCard {...customContent}>
            <CreateAccountFormIndividualStandardCoinhub onProfileTypeChanged={onProfileTypeValueChanged} onProductTypeChanged={onProductTypeValueChanged} data={formData.current} />
        </SingleCard>
    )
}

export default CreateAccountFormCoinhub
